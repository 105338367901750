var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v(_vm._s(_vm.$t('Informations')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('The fields with (*) are required')))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name')+'*',"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.driverData.name),callback:function ($$v) {_vm.$set(_vm.driverData, "name", $$v)},expression:"driverData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nickname'),"label-for":"nickname"}},[_c('b-form-input',{attrs:{"id":"nickname"},model:{value:(_vm.driverData.nickName),callback:function ($$v) {_vm.$set(_vm.driverData, "nickName", $$v)},expression:"driverData.nickName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Customer'),"label-for":"customer"}},[(!_vm.driverData.id)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps},on:{"selected":_vm.selectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,false,3761623635),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),(_vm.driverData.id)?_c('b-form-input',{attrs:{"id":"customer","disabled":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Identifier Card'),"label-for":"identifier-card"}},[_c('b-form-input',{attrs:{"id":"identifier-card"},model:{value:(_vm.driverData.cardIdentifier),callback:function ($$v) {_vm.$set(_vm.driverData, "cardIdentifier", $$v)},expression:"driverData.cardIdentifier"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Document')+'*',"label-for":"document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document"},model:{value:(_vm.driverData.document),callback:function ($$v) {_vm.$set(_vm.driverData, "document", $$v)},expression:"driverData.document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Birth Date')+'*',"label-for":"birth-date"}},[_c('validation-provider',{attrs:{"name":"birth-date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"birth-date"},model:{value:(_vm.driverData.birthDate),callback:function ($$v) {_vm.$set(_vm.driverData, "birthDate", $$v)},expression:"driverData.birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('License Type')+'*',"label-for":"license-type"}},[_c('validation-provider',{attrs:{"name":"license-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"license-type"},model:{value:(_vm.driverData.licenseType),callback:function ($$v) {_vm.$set(_vm.driverData, "licenseType", $$v)},expression:"driverData.licenseType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Is Corporate')+'*',"label-for":"is-corporate"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.isCorporateOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"is-corporate"},model:{value:(_vm.driverData.isCorporate),callback:function ($$v) {_vm.$set(_vm.driverData, "isCorporate", $$v)},expression:"driverData.isCorporate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Notes'),"label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","rows":"5"},model:{value:(_vm.driverData.note),callback:function ($$v) {_vm.$set(_vm.driverData, "note", $$v)},expression:"driverData.note"}})],1)],1),(!_vm.user.role.endsWith('VI'))?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }